import moment from "moment";
import { ACTIVE_PLANS, APPBANNER } from "../../constants/myaccount";
import { deleteCustomerCookie, getIsAuthenticateCookie, SetCSRFToken, setIsAuthenticate, URLS } from "../../api/cookies";
import { SignOut } from "../../api/myaccount";
import store from "../../redux";
import { hasPageLoaded } from "../../redux/actions/cartActions";
import { resetCustomer } from "../../redux/actions/customerActions";
import router from "next/router";
import { HOMECARE_PLAN_LIST } from "../../constants";

export const getAppBannerInfo = (props) => {
    if (props.page === "plans") {
        if (props.myaccount.userPlans.some((item)=> ACTIVE_PLANS.includes(item.plan_status))) {
            return APPBANNER[2]
        } 
        else {
            return APPBANNER[1]
        }
    }
    else if (props.page === "requests") {
        if (!props.myaccount.has_plans) {
            return APPBANNER[1]
        }
        else {
            return APPBANNER[3]
        }
    }
    else if(props.page === "orders" || props.page === "ordersid"){
        return APPBANNER[5]
    }
    else if(props.page === "activation"){
        return APPBANNER[2]
    }
    else {
        return APPBANNER[4]
    }
}

const getDays = tenureDate => {
    const today = moment();
    const date = moment(tenureDate, 'DD MMM YYYY');
    const days = date.startOf('day').diff(today.startOf('day'), 'days');
    const noOfDays: number = + days
    return noOfDays;
};

export const getMomentObject = (timestamp, format) => {
    moment.locale(); // 'en'
    let m = moment(new Date(timestamp));
    if(m.format('DD') == 'Invalid date'){
      m = moment(timestamp, format);
    }
    return m;
};

const BLOCKED_CHANNELS = ['tata-aig'];

export const SELFSERVE_DETAIL_MESSAGE = {
    'tata-aig':
      'Please contact TATA AIG on 1800-266-7780 for generating a Claim ID and register your Service Request',
  }
export const getPlanTopInfo = plan => {
    if (plan.plan_status === 'Active') {
        const tenure = plan.plan_tenure.split('to')[1].trim();
        const date = moment(tenure, 'DD MMM YYYY');
        const days = getDays(date);
        const text = `Expiring in ${days} ${days>1 ? "days":"day"}`;
        if (days <= 3) {
            return (
                {
                    helpText: text,
                    colorCode: "red",
                    hasAction: false,
                    iconName: "expiring"
                }
            );
        } else {
            return (
                {
                    helpText: "Plan Active",
                    colorCode: "green",
                    hasAction: false,
                    iconName: "active",
                }
            );
        }
    } else if (plan.plan_name === 'Instarepair') {
        return (
            {
                helpText: "Repair in Progress",
                colorCode: "green",
                hasAction: false,
                srNo: plan.sr_id,
                iconName: "active"
            }
        );
    } else if (plan.plan_status === 'Under Brand Warranty') {
        return (
            {
                helpText: "Plan not active",
                colorCode: "gray",
                hasAction: false,
                iconName: "under-brand-warranty"
            }
        );
    } else if (plan.plan_status === 'NOT STARTED') {
        const tenure = plan.plan_tenure.split('to')[0].trim();
        const date = moment(tenure, 'DD MMM YYYY');
        const days = getDays(date).toString();
        const text = `Plan starts in ${days} days`;

        return (
            {
                helpText: text,
                colorCode: "blue",
                hasAction: false,
                iconName: "plan-not-started"
            }
        );
    } else if (['Activation Pending', 'Unknown'].includes(plan.plan_status)) {
        return (
            {
                helpText: "Action Required",
                colorCode: "orange",
                hasAction: false,
                iconName: "pending"
            }
        );
    } else if (plan.plan_status === 'Expired') {
        const tenure = plan.plan_tenure.split('to')[1].trim();
        const date = moment(tenure, 'DD MMM YYYY').format('MMMM DD, YYYY');
        const text = `Plan expired on ${date}`;

        return (
            {
                helpText: text,
                colorCode: "gray",
                hasAction: false,
                iconName: "expired"
            }
        );
    } else if (plan.plan_status === 'Registration Failed') {
        const text = 'Registration Failed';
        return (
            {
                helpText: text,
                colorCode: "red",
                hasAction: false,
                iconName: "registraion-failed"
            }
        );
    } else if (plan.plan_status === 'Inspection Pending') {
        return (
            {
                helpText: "Inspection Pending",
                colorCode: "orange",
                hasAction: false,
                iconName: "pending",
            }
        );
    } else if (plan.plan_status === 'Lapsed') {
        return (
            {
                helpText: "Plan Lapsed",
                colorCode: "gray",
                hasAction: false,
                iconName: "cancelled"
            }
        );
    } else if (plan.plan_status === 'Cancelled') {
        return (
            {
                helpText: "Plan Cancelled",
                colorCode: "gray",
                hasAction: false,
                iconName: "cancelled"
            }
        );
    } else if (plan.plan_status === 'Consumed') {
        return (
            {
                helpText: "Plan Consumed",
                colorCode: "gray",
                hasAction: false,
                iconName: "cancelled",
            }
        );
    }
};

export const buttonData = plan => {
    let enabled: boolean = true;
    let label = 'Raise Service Request';
    let link = `/selfserve?osid=${plan.tokenized_osid}`

    if (plan.blacklisted) {
        enabled = true; 
    } else if (BLOCKED_CHANNELS.includes(plan.channel_name)) {
        enabled = true;
    } else if (
        !plan.plan_validity.is_valid &&
        !plan.plan_validity.is_hc_valid
    ) {
        if (
            plan.active_sr_exists ||
            ['Activation Pending', 'Unknown', 'Under Brand Warranty'].includes(
                plan.plan_status,
            )
        ) {
            enabled = false;
        } else {
            enabled = true;
        }
    } else if (plan.plan_status === 'Cancelled') {
        enabled = true;
    } else if (
        plan.plan_name === 'Instarepair' &&
        plan.plan_status === 'Consumed'
    ) {
        enabled = true;
    } else if (HOMECARE_PLAN_LIST.includes(plan.plan_name)) {
        enabled = false;
    } else {
        enabled = false;
    }

    if (HOMECARE_PLAN_LIST.includes(plan.plan_name)) {
        if (plan.active_sr_exists) {
            if (['Lapsed', 'Expired'].includes(plan.plan_status)) {
                label = 'Track Request';
                link = '/account/requests';
            } else if (
                plan.open_sr_count > 0 &&
                plan.preventive_maintainance_data.has_pm &&
                plan.preventive_maintainance_data.remaining_pm === 0
            ) {
                label = 'Track Request';
                link = plan.open_sr_count > 1 || plan.plan_name === 'HomeCare Comprehensive'
                    ? '/account/requests'
                    : `/selfserve/tracker/service-request/${plan.sr_id}`;
            } else {
                label = 'Raise Service Request';
                link = `/selfserve?osid=${plan.tokenized_osid}`;
            }
        } else if (['Lapsed', 'Expired'].includes(plan.plan_status)) {
            enabled = true;
        }
    } else if (plan.reopen_eligible && plan.covers === 1) {
        label = 'Raise Service Request';
        link = `/selfserve?osid=${plan.tokenized_osid}`
    } else if (plan.active_sr_exists) {
        label = 'Track Request'
        link = `/selfserve/tracker/service-request/${plan.sr_id}`
    } else if (plan.plan_status === 'Inspection Pending') {
        label = `Chat With Us`;
        enabled = false;
        link = "https://onst.in/4dCuJA"
    } else if (!plan.is_registered && !['Active'].includes(plan.plan_status) && plan.plan_name != 'AMC Gold') {
        label = 'Activate Now';
        link = `/account/activation/${plan.osid}`
        if(!router.query.activation_source){
            link = link+'?activation_source=myaccount'
        }
    } else if (
        [
            ' NOT STARTED',
            'Under Brand Warranty',
            'Registration Failed',
        ].includes(plan.plan_status)
    ) {
        label = `Chat With Us`;
        enabled = false;
        link = "https://onst.in/4dCuJA"
    }

    return [label, enabled, link];
};

export const getPlanStatus = plan => {
    if (BLOCKED_CHANNELS.includes(plan.channel_name)) {
      let details_message = SELFSERVE_DETAIL_MESSAGE[plan.channel_name];
      return (
        details_message
      );
    } else if (plan.active_sr_exists) {
      return (
          `Last Updated on ${plan.sr_time_stamp}`
      );
    } else if (
      ['Under Brand Warranty', ' NOT STARTED'].includes(plan.plan_status)
    ) {
      return (
        "Contact helpdesk for support"
      );
    } else if (['Activation Pending', 'Unknown'].includes(plan.plan_status)) {
      return (
          `Purchased on ${moment(
            plan.plan_purchased_date,
            'YYYY-MM-DD',
          ).format('MMMM DD, YYYY')}`
      );
    }
};

export const signOut = () => {
    if(getIsAuthenticateCookie()){
        store.dispatch(resetCustomer())
        deleteCustomerCookie()
        setIsAuthenticate(false)
        SignOut().then(() => {
            SetCSRFToken(URLS.SET_CSRF);
            store.dispatch(hasPageLoaded(true))
          });
    }else{
        SetCSRFToken(URLS.SET_CSRF);
        store.dispatch(hasPageLoaded(true))
    }
}

// This Function used for blocking sales touch 
// if cx comes from comms and channel is not consumer
export const blockSalesTouchPoint = (router) => {
    if(router.asPath && router.asPath.includes('params=ordersummary')){
        return true
    }
    return !!(router.query?.params=='ordersummary')
}

export const getOSIDs = (data) => {
    let osids = ''
    if(data.plans){
        data.plans.forEach(plan => {
            osids += plan.osid + ', '
        })
    }
    return osids
}
