export const UTM_FIELDS = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_content',
  'ref',
  'ppc',
  'gclid',
  'fbclid',
  'discount',
  'osg_campaign'
]

//This UTM params get cleared with the session
export const QUERY_PARAM = [
  'discount'
]

export const HOMECARE_PLANS = [
  'HomeCare Basic',
  'Home Maintenance Plan',
  'HomeCare Lite',
  'HomeCare Fest',
  'DiwaliCare',
  'HomeCare Gold',
  'Onsitego Pass'
]
export const HOMECARE_PLAN_LIST = [
  'HomeCare Basic',
  'Home Maintenance Plan',
  'HomeCare Lite',
  'HomeCare Premium',
  'HomeCare Comprehensive',
  'HomeCare Fest',
  'DiwaliCare',
  'HomeCare Gold',
  'Onsitego Pass'
]
export const HOMECARE_PREMIUM_PRODUCTS = [
  'HomeCare Premium',
  'HomeCare Comprehensive'
]

export const HOMECARE_TITLES = {
  'HomeCare Basic': 'HomeCare'
}

export const ADDITIONAL_COOKIES = "ref,ppc,gclid,fbclid,discount,osg_campaign"

export const CHANNEL_FOR_AGENT_APPLICATION = ['website-tele', 'onsitego-scouts']

export const DEFAULT_TIME_SLOTS = [{
                                      key: '10AM - 2PM',
                                      value: '10AM - 2PM',
                                      text: '10AM - 2PM'
                                    },
                                    {
                                      key: '2PM - 6PM',
                                      value: '2PM - 6PM',
                                      text: '2PM - 6PM'
                                    }
                                  ]

export const SELECT_DEVICE = "Previously Serviced Devices"

export const ADD_DEVICE = "Add Device"

export const HOMECARE_FEST_MAX_CLAIMS = 3

export const AFTRENOON_TIME_SLOTS = [
  {
    key: '2PM - 6PM',
    value: '2PM - 6PM',
    text: '2PM - 6PM'
  }
]

export const IPROTECT_PLANS = [
  'iprotect',
  'iprotect enterprise',
  'iprotect gold',
  'vs+ iprotect'
]

export const AB_EXPERIMENT_CONFIG = {
  'rate_us_ab': {
    'status': false,
    'cookie_expiry': 30,
    'name': 'rate_us'
  },
  'status': true
}

export const HC_PRODUCT_GROUPS = ['homecare'];
export const IR_PRODUCT_GROUPS = ['insta-repair'];
export const AMC_GROUPS = ['amc'];
export const TOTAL_PROTECTION_GROUPS = ['total-protection']
export const SPILLS_AND_DROPS = ['spills-and-drops']
export const EXTENDED_WARRANTY = ['extended-warranty']
export const regExpEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
export const TIMESTAMP_FORMAT = "ddd, Do MMM YY - hh:mmA";
export const DISABLED_INPUT_INJECTION_CHARACTERS = [ "<", ">" ]
